import { forwardRef } from "react";
const SearchInput = forwardRef(({ onChange }, ref) => {
    return (
        <form
            className="relative flex items-center w-full h-10 px-3 py-0 m-0 text-sm font-medium leading-7 text-gray-500 bg-gray-200 rounded-full focus:outline-sky-700"
            style={{ boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px" }}
        >
            <label
                className="flex items-center justify-center p-0 m-0 font-medium cursor-default text-sky-700"
                htmlFor="docsearch-input"
                id="docsearch-label"
            >
                <svg
                    width="20"
                    height="20"
                    className="block w-4 h-4 mx-1 leading-7 text-gray-400 align-middle"
                    viewBox="0 0 20 20"
                    style={{ strokeWidth: "1.6px" }}
                >
                    <path
                        d="M14.386 14.386l4.0877 4.0877-4.0877-4.0877c-2.9418 2.9419-7.7115 2.9419-10.6533 0-2.9419-2.9418-2.9419-7.7115 0-10.6533 2.9418-2.9419 7.7115-2.9419 10.6533 0 2.9419 2.9418 2.9419 7.7115 0 10.6533z"
                        stroke="currentColor"
                        fill="none"
                        fillRule="evenodd"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="text-gray-400"
                    ></path>
                </svg>
            </label>
            <div className="hidden p-0 m-0 font-medium text-gray-500">
                <svg
                    viewBox="0 0 38 38"
                    stroke="currentColor"
                    strokeOpacity=".5"
                    className="block w-4 h-4 mx-1 leading-7 text-gray-400 align-middle"
                >
                    <g fill="none" fillRule="evenodd" className="text-gray-400">
                        <g transform="translate(1 1)" strokeWidth="2">
                            <circle
                                strokeOpacity=".3"
                                cx="18"
                                cy="18"
                                r="18"
                            ></circle>
                            <path d="M36 18c0-9.94-8.06-18-18-18">
                                <animateTransform
                                    attributeName="transform"
                                    type="rotate"
                                    from="0 18 18"
                                    to="360 18 18"
                                    dur="1s"
                                    repeatCount="indefinite"
                                ></animateTransform>
                            </path>
                        </g>
                    </g>
                </svg>
            </div>
            <input
                ref={ref}
                className="flex-1 w-4/5 h-full py-3 pl-2 pr-0 m-0 leading-tight bg-transparent cursor-text text-zinc-800 focus:outline-sky-700"
                aria-autocomplete="both"
                aria-labelledby="docsearch-label"
                id="docsearch-input"
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                enterKeyHint="go"
                spellCheck="false"
                placeholder="Search docs"
                maxLength="64"
                type="search"
                onChange={onChange}
                data-last-active-input=""
                aria-activedescendant="docsearch-item-0"
                aria-controls="docsearch-list"
                style={{
                    outline: "none",
                    paddingInline: "0.5rem 0px",
                    outlineOffset: "-2px",
                }}
            />
            <button
                type="reset"
                title="Clear the query"
                className="right-0 items-center justify-center hidden p-px m-0 text-center normal-case appearance-none cursor-pointer bg-none"
                hidden=""
                style={{
                    fontSize: "128%",
                    animation: "auto ease 0s 1 normal none running none",
                    borderRadius: "50%",
                    strokeWidth: "1.4px",
                }}
            >
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    className="block font-medium text-gray-500 align-middle"
                >
                    <path
                        d="M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z"
                        stroke="currentColor"
                        fill="none"
                        fillRule="evenodd"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="leading-7"
                    ></path>
                </svg>
            </button>
        </form>
    );
});

export default SearchInput;
